import { createAsyncAction } from 'typesafe-actions';
import type { FichesStatistiquesParameters, FicheStatistiques, GeneralStatistiques } from './types';

// Charge la liste des statistiques générales
export const loadGeneralStatistiquesAsync = createAsyncAction(
    'LOAD_GENERAL_STATISTIQUES_REQUEST',
    'LOAD_GENERAL_STATISTIQUES_SUCCESS',
    'LOAD_GENERAL_STATISTIQUES_FAILURE',
)<FichesStatistiquesParameters, GeneralStatistiques, string>();

// Charge la liste des statistiques de fiches
export const loadFichesStatistiquesAsync = createAsyncAction(
    'LOAD_FICHES_STATISTIQUES_REQUEST',
    'LOAD_FICHES_STATISTIQUES_SUCCESS',
    'LOAD_FICHES_STATISTIQUES_FAILURE',
)<FichesStatistiquesParameters, FicheStatistiques[], string>();
