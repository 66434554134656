import Axios from "axios";
import qs from 'qs';
import { ArianeInfo, NiveauEntitiesInfos, ListRequestParameters, CreateModifyEntityInfos } from "features/admin/participants/src/store/types";
import { Entite } from "classes/referentiel/Entite.class";
import { Niveau } from "classes/referentiel/Niveau.class";
import { EntiteChampValue } from "classes/referentiel/EntiteChampValue.class";
import { EntiteCaracteristiqueValue } from "classes/referentiel/EntiteCaracteristiqueValue.class";
import { Acteur } from "classes/Acteur.class";
import { Role } from "classes/referentiel/Role.class";
import { ActeurEntiteRole } from "classes/referentiel/ActeurEntiteRole.class";
import { NiveauChamp } from "classes/referentiel/NiveauChamp.class";
import { NiveauCaracteristique } from "classes/referentiel/NiveauCaracteristique.class";
import { DeepRawify } from "types";


export type ActeurExistsReturn = {
    entite : string,
    niveau : string
}
export class ParticipantsService {
    private static instance: ParticipantsService;

    public static getInstance(): ParticipantsService {
        if (!ParticipantsService.instance) {
            ParticipantsService.instance = new ParticipantsService();
        }

        return ParticipantsService.instance;
    }

    public async loadArianeInfos(): Promise<ArianeInfo[]> {
        return Axios.get<{ content: ArianeInfo[] }>(`index.php?${qs.stringify({
            rub: 3,
            p: 1,
        })}`)
            .then(({ data: { content } }) => content);
    }

    public async loadEntities(niveau?: number, idPere?: string): Promise<Entite[]> {
        return Axios.get<{ content: DeepRawify<Entite>[] }>(`index.php?${qs.stringify({
            rub: 3,
            p: 2,
            niveau,
            idPere
        })}`)
            .then(({ data: { content } }) => content.map((entite) => new Entite(entite)));
    }


    public async loadNiveau(niveau: number): Promise<Niveau> {
        return Axios.get<{ content: DeepRawify<Niveau> }>(`index.php?${qs.stringify({
            rub: 3,
            p: 3,
            niveau
        })}`)
            .then(({ data: { content } }) => new Niveau(content))
    }

    public async loadEntity(idEntite: string): Promise<Entite> {
        return Axios.get<{ content: DeepRawify<Entite> }>(`index.php?${qs.stringify({
            rub: 3,
            p: 4,
            idEntite
        })}`)
            .then(({ data: { content } }) => new Entite(content))
    }

    public async loadNiveauEntitiesInfos(parametersInfos: ListRequestParameters): Promise<NiveauEntitiesInfos> {
        return Axios.get<{ content: DeepRawify<NiveauEntitiesInfos> }>(`index.php?${qs.stringify({
            rub: 3,
            p: 4,
            ...parametersInfos,
        })}`)
            .then(({ data: { content } }) => ({
                entities: content.entities.map((entity) => new Entite(entity)),
                niveau: new Niveau(content.niveau),
                arianeInfos: content.arianeInfos,
                currentEntity: content.currentEntity ? new Entite(content.currentEntity) : null,
                fatherEntity: content.fatherEntity ? new Entite(content.fatherEntity) : null,
                niveauMax: content.niveauMax,
                libelleNiveauMin: content.libelleNiveauMin,
                niveauPere: new Niveau(content.niveauPere),
                niveauChamps: content.niveauChamps.map((niveauChamp) => new NiveauChamp(niveauChamp)),
                niveauCaracteristiques: content.niveauCaracteristiques.map((niveauCaracteristique) => new NiveauCaracteristique(niveauCaracteristique)),
                actors: content.actors.map((actor) => new Acteur(actor)),
                roles: content.roles.map((role) => new Role(role))
            }));
    }
    public async loadCreateModifyEntityInfos(parametersInfos: ListRequestParameters): Promise<CreateModifyEntityInfos> {
        return Axios.get<{ content: DeepRawify<CreateModifyEntityInfos> }>(`index.php?${qs.stringify({
            rub: 3,
            p: 5,
            ...parametersInfos,
        })}`)
            .then(({ data: { content } }) => ({
                entities: content.entities.map((entity) => new Entite(entity)),
                niveau: new Niveau(content.niveau),
                currentEntity: content.currentEntity ? new Entite(content.currentEntity) : null,
                fatherEntity: content.fatherEntity ? new Entite(content.fatherEntity) : null,
                niveauMax: content.niveauMax,
                niveauPere: new Niveau(content.niveauPere),
                niveauChamps: content.niveauChamps.map((niveauChamp) => new NiveauChamp(niveauChamp)),
                niveauCaracteristiques: content.niveauCaracteristiques.map((niveauCaracteristique) => new NiveauCaracteristique(niveauCaracteristique)),
                acteur: new Acteur(content.acteur),
                roles: content.roles.map((role) => new Role(role))            
            }));
    }

    public async saveEntity(entity: Entite, entiteChampValues: EntiteChampValue[], entiteCaracteristiqueValues: EntiteCaracteristiqueValue[], estModification: boolean): Promise<string> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 3,
                p: 6,

            })}`,
            qs.stringify({
                entity: JSON.stringify(entity.toDatabaseObject()),
                entiteChampValues: JSON.stringify(entiteChampValues),
                entiteCaracteristiqueValues: JSON.stringify(entiteCaracteristiqueValues),
                estModification: JSON.stringify(estModification)
            })
        )
            .then(() => '')
            .catch(({ response: { data: { message } } }) => message);

    }

    public async deleteEntity(idEntite: string): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 3,
                p: 7,

            })}`,
            qs.stringify({ idEntite })
        )
            .then(() => true)
            .catch(() => false);

    }

    public async deleteActeurEntiteRole(idEntite: string, idActeur: string): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 3,
                p: 11,

            })}`,
            qs.stringify({ idEntite, idActeur })
        )
            .then(() => true)
            .catch(() => false);

    }

    public async saveActeur(act: Acteur, acteurCaracteristiqueValues?: EntiteCaracteristiqueValue[], acteurEntiteRole?: ActeurEntiteRole, estModification?: boolean): Promise<boolean> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 3,
                p: 9,

            })}`,
            qs.stringify({
                acteur: JSON.stringify(act.toDatabaseObject()),
                estModification: JSON.stringify(estModification),
                acteurCaracteristiqueValues: JSON.stringify(acteurCaracteristiqueValues),
                acteurEntiteRole: JSON.stringify(acteurEntiteRole?.toDatabaseObject()),
            })
        )
            .then(() => true)
            .catch(() => false);

    }

    public async acteurExists(act: Acteur, niveau: number): Promise<ActeurExistsReturn | string > {
        return Axios.get(
            `index.php?${qs.stringify({
                rub: 3,
                p: 12,
                idActeur: JSON.stringify(act.idActeur),
                niveau
            })}`
        )
            .then(({data : { content }}) => content)
            .catch((content) => content);

    }

    public async loadNbrMaxUtilisateursAtteint(): Promise<boolean> {
        return Axios.get(
            `index.php?${qs.stringify({
                rub: 3,
                p: 13
            })}`
        )
            .then(({data : { content }}) => content)
            .catch((content) => content);

    }

}
