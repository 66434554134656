import Axios from "axios";
import qs from 'qs';
import { MonProfilInfos } from "features/parametres/src/monProfil/store/types";
import { Post } from "classes/murs/Post.class";
import { QuizBadge } from "classes/animations/QuizBadge.class";
import { Acteur } from "classes/Acteur.class";
import { TypePost } from "classes/murs/TypePost.class";
import { AdminParametresTypesPostInfos } from 'features/admin/parametres/src/typesPost/store/types';
import { DeepRawify } from "types";

export class ParametresService {
    private static instance: ParametresService;

    public static getInstance(): ParametresService {
        if (!ParametresService.instance) {
            ParametresService.instance = new ParametresService();
        }

        return ParametresService.instance;
    }

    public loadMonProfilInfos(): Promise<MonProfilInfos> {
        const params = {
            rub: 68553,
            p: 18
        };

        return Axios.get<{ content: DeepRawify<MonProfilInfos> }>(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content } }) => ({
                aPropos: new Acteur(content.aPropos),
                badges: content.badges.map((badge) => new QuizBadge(badge)),
                favoris: content.favoris.map((post) => new Post(post)),
                mesPosts: content.mesPosts.map((post) => new Post(post))
            }));
    }

    // Récupération des types de posts en fonction du type de mur
    public loadTypesPost(typeMur: number): Promise<TypePost[]> {
        const params = {
            rub: 306,
            typeMur,
            p: 3,
            sp: 2
        };

        return Axios.get<{ content: { typesPost: DeepRawify<TypePost>[] } }>(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content } }) => content.typesPost.map((typePost) => new TypePost(typePost)));
    }

    // Récupération d'un type de posts en fonction de son idTypePost et du type de mur
    public loadTypePost(typeMur: number, idTypePost: number): Promise<TypePost> {
        return Axios.post(
            `index.php?${qs.stringify({
                rub: 306,
                typeMur,
                idTypePost,
                p: 3,
                sp: 3
            })}`,
            qs.stringify({ idTypePost }),
        )
        .catch(function ({ response: { data: { message } } }) {
            throw message;
        })
        .then(({ data: { content } }) => new TypePost(content));
    }

    // Récupération des infos des types de post et types de murs par défaut à l'initialisation de la page
    public loadTypesPostInfos(): Promise<AdminParametresTypesPostInfos> {
        const params = {
            rub: 306,
            p: 3,
            sp: 1
        };

        return Axios.get<{ content: DeepRawify<AdminParametresTypesPostInfos> }>(`index.php?${qs.stringify(params)}`)
            .catch(function ({ response: { data: { message } } }) {
                throw message;
            })
            .then(({ data: { content } }) => ({
                typesMurs: content.typesMurs,
                typesPost: content.typesPost.map((typePost) => new TypePost(typePost))
            }));
    }

    public async loadMailContact(): Promise<string> {
        return Axios.get(
            `index.php?${qs.stringify({
                rub: 30,
                p: 1
            })}`
        )
            .then(({data : { content }}) => content)
            .catch((content) => content);

    }

}
