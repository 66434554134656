import { DeepPartial } from 'redux';
import { LevelUpService } from 'services';
import { DeepRawify } from 'types';
import { v4 } from 'uuid';
import { FicheAnswer } from './FicheAnswer.class';

/**
 * Question d'une fiche
 */
export class FicheQuestion {

    public uid: string = v4();

    /**
     * Identifiant de la question
     */
    public idQuestion: number;

    /**
     * Identifiant de la fiche associée
     */
    public idFiche: number;

    /**
     * Identifiant du groupe associé
     * Pourrait être amené à changer mais pour l'insant,
     * 1 = Quiz
     * 2 = Expert
     */
    public idGroupe: number;

    /**
     * Type de question
     * 1 = Choix unique
     * 2 = Choix multiples
     */
    public type: number;

    /**
     * Libellé de la question
     */
    public title: string;

    /**
     * Afficher une explication en cas de mauvaise réponse ?
     */
    public explanation: boolean;

    /**
     * Texte d'explication en cas de mauvaise réponse
     */
    public explanationText: string;

    /**
     * Affiché / en ligne ?
     */
    public active: boolean;

    /**
     * Champ spécial réponses
     */
    public answers: FicheAnswer[];

    /**
     * Champ spécial index dans le quiz pour affichage front
     */
    public index: number;

    /**
     * Champ spécial total dans le quiz pour affichage front
     */
    public total: number;

    /**
     * Champ spécial contenant le libellé de la fiche
     */
    public libelleFiche: string;

    private levelUpService = LevelUpService.getInstance();

    /**
     * Constructeur d'un objet FicheQuestion
     */
    constructor(row?: DeepPartial<DeepRawify<FicheQuestion>>) {
        if (row) {
            const {
                idQuestion, idFiche, idGroupe, title, type, explanationText, 
                explanation, active, answers, index, total, libelleFiche
            } = row;

            this.idQuestion = idQuestion ?? null;
            this.idFiche = idFiche ?? null;
            this.idGroupe = idGroupe ?? null;
            this.title = title ?? null;
            this.type = type ?? 1;
            this.explanationText = explanationText ?? null;
            this.explanation = explanation ?? false;
            this.active = active ?? null;
            this.index = index ?? 0;
            this.total = total ?? 0;
            this.libelleFiche = libelleFiche ?? '';
            this.answers = answers ? answers.map((a) => new FicheAnswer(a)) : [];
        } else {
            this.answers = [];
        }
    }

    /**
     * Méthode de renvoi de l'objet en back sous la forme d'un tableau
     */
    public toRaw() {
        return {
            idQuestion: this.idQuestion,
            idFiche: this.idFiche,
            idGroupe: this.idGroupe,
            title: this.title,
            type: this.type,
            explanationText: this.explanationText,
            explanation: this.explanation,
            active: this.active,
            answers: this.answers.map((a) => a.toRaw())
        };
    }

    /**
     * Sauvegarde la question en back
     */
    public save() {
        return this.levelUpService.saveQuestion(this);
    }

    /**
     * Suppression de la question en back
     */
    public delete() {
        return this.levelUpService.deleteQuestion(this.idQuestion);
    }

}
