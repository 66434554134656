import { combineReducers } from 'redux';
import { RootAction } from 'store/types';
import { createReducer } from 'typesafe-actions';
import cloneDeep from 'lodash/cloneDeep'
import {
    loadGeneralStatistiquesAsync,
    loadFichesStatistiquesAsync,
} from './actions';
import { FicheStatistiques, GeneralStatistiques } from './types';

const generalStatistiques = createReducer<GeneralStatistiques | null, RootAction>(null)
    .handleAction(loadGeneralStatistiquesAsync.success, (_state, action) => cloneDeep(action.payload));

const isLoadingGeneralStatistiques = createReducer<boolean, RootAction>(false)
    .handleAction([loadGeneralStatistiquesAsync.request], () => true)
    .handleAction([
        loadGeneralStatistiquesAsync.success, 
        loadGeneralStatistiquesAsync.failure,
    ], () => false);

const fichesStatistiques = createReducer<FicheStatistiques[] | null, RootAction>(null)
    .handleAction(loadFichesStatistiquesAsync.success, (_state, action) => cloneDeep(action.payload));

const isLoadingFichesStatistiques = createReducer<boolean, RootAction>(false)
    .handleAction([loadFichesStatistiquesAsync.request], () => true)
    .handleAction([
        loadFichesStatistiquesAsync.success, 
        loadFichesStatistiquesAsync.failure,
    ], () => false);

export const levelUpStatistiquesReducers = combineReducers({
    generalStatistiques,
    isLoadingGeneralStatistiques,
    fichesStatistiques,
    isLoadingFichesStatistiques
});

export default levelUpStatistiquesReducers;