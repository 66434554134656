import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { RootAction } from 'store/types';
import { loadCurrentActeurAsync, loadActeurAsync, loadActeurInfosAsync, loadCurrentActeurInfosAsync, setLoggedIn } from './acteurActions';
import { Acteur } from 'classes/Acteur.class';
import { loadMurInfos } from 'features/murs/src/store/actions';
import { loadMonProfilInfosAsync } from 'features/parametres/src/store/actions';
import { Statistic } from 'classes/Statistic/Statistic.class';
import { Role } from 'classes/referentiel/Role.class';
import { loadCreateModifyEntityInfosAsync } from 'features/admin/participants/src/store/actions';
import { Droits } from './acteurTypes';

const isLoading = createReducer<boolean, RootAction>(true)
    .handleAction([loadCurrentActeurAsync.request, 
    loadActeurAsync.request,
    loadMurInfos.request,
    loadActeurInfosAsync.request,
    loadCurrentActeurInfosAsync.request,
    loadCreateModifyEntityInfosAsync.request,
    loadMonProfilInfosAsync.request], () => true)
    .handleAction([loadCurrentActeurAsync.success, 
    loadActeurAsync.success,
    loadMurInfos.success,
    loadActeurInfosAsync.success,
    loadCurrentActeurInfosAsync.success,
    loadCreateModifyEntityInfosAsync.success,
    loadMonProfilInfosAsync.success,
    loadCurrentActeurAsync.failure,
    loadActeurAsync.failure,
    loadMurInfos.failure,
    loadActeurInfosAsync.failure,
    loadCurrentActeurInfosAsync.failure,
    loadCreateModifyEntityInfosAsync.failure,
    loadMonProfilInfosAsync.failure,], () => false);

// L'acteur courant avec lequel on est connecté
const current = createReducer<Acteur, RootAction>(null)
    .handleAction([loadCurrentActeurAsync.success, loadActeurAsync.success], (_state, action) => action.payload)
    .handleAction([loadMurInfos.success, loadActeurInfosAsync.success, loadCurrentActeurInfosAsync.success], (_state, action) => action.payload.acteur)
    .handleAction(loadMonProfilInfosAsync.success, (_state, action) => action.payload.aPropos);

// L'acteur qu'on veut créer ou modifier
const acteurSelected = createReducer<Acteur, RootAction>(null)
    .handleAction([loadMurInfos.success, loadActeurInfosAsync.success, loadCreateModifyEntityInfosAsync.success], (_state, action) => action.payload.acteur);


const statistics = createReducer<{ [label: string]: string }, RootAction>({})
    .handleAction(loadActeurInfosAsync.success, (_state, action) => action.payload.statistics)

const currentIsMe = createReducer<boolean, RootAction>(false)
    .handleAction([loadCurrentActeurAsync.success, loadMurInfos.success, loadMonProfilInfosAsync.success, loadCurrentActeurInfosAsync.success], (_state, action) => true)
    .handleAction(loadActeurInfosAsync.success, (_state, action) => action.payload.currentIsMe)


const monRole = createReducer<Role, RootAction>(null)
    .handleAction([loadCurrentActeurInfosAsync.success], (_state, action) => action.payload.monRole)

const loggedIn = createReducer<boolean, RootAction>(true)
    .handleAction([setLoggedIn], (_state, action) => action.payload);

const droits = createReducer<Droits, RootAction>({})
    .handleAction(loadCurrentActeurInfosAsync.success, (_state, action) => action.payload.droits)

const acteurReducer = combineReducers({
    isLoading,
    current,
    acteurSelected,
    statistics,
    currentIsMe,
    monRole,
    loggedIn,
    droits
});

export default acteurReducer;
