import { ChallengeService } from "services";
import moment, { Moment } from "moment";
import { MulticritereCritere } from "./MulticritereCritere.class";
import { Acteur } from "classes/Acteur.class";
import { MulticritereSnapshot } from "./MulticritereSnapshot.class";
import { DeepRawify } from "types";
import { DeepPartial } from "redux";

export class MulticritereClassement {

    public idSuiviMulticritereClassement: number = null;

    public idChallenge: number = null;

    public idSuivi: number = null;

    public idEntite: string = null;

    public dateTraitement: Moment = null;

    public dateFraicheur: Moment = null;

    public joursRestants: number = null;

    public joursRestantsPourcentage: number = null;

    public idGroupe: number = null;

    public libelleGroupe: string = null;

    public resultatCritere: number = null;

    public objectifCritere: number = null;

    public rsuroCritere: number = null;

    public pointsCritere: number = null;

    public pointsCriterePourcent: number = null;

    public idCritere: number = null;

    public rang: number = null;

    public rangMoinsUn: number = null;

    public rangMaxClassement: number = null;

    public progressionRang: number = null;

    public idPere: string = null;

    public infosActeur: Acteur = null;

    public infosPere: MulticritereSnapshot = null;

    public infosCritere: MulticritereCritere[] = [];

    private challengeService = ChallengeService.getInstance();

    constructor(row?: DeepPartial<DeepRawify<MulticritereClassement>>) {

        if (row) {

            const {
                idSuiviMulticritereClassement,
                idChallenge,
                idSuivi,
                idEntite,
                dateTraitement,
                dateFraicheur,
                joursRestants,
                joursRestantsPourcentage,
                idGroupe,
                libelleGroupe,
                resultatCritere,
                objectifCritere,
                rsuroCritere,
                pointsCritere,
                pointsCriterePourcent,
                idCritere,
                rang,
                rangMoinsUn,
                rangMaxClassement,
                progressionRang,
                idPere,
                infosActeur,
                infosPere,
                infosCritere
            } = row;

            this.idSuiviMulticritereClassement = idSuiviMulticritereClassement;

            this.idChallenge = idChallenge;

            this.idSuivi = idSuivi;

            this.idEntite = idEntite;

            this.dateTraitement = moment(dateTraitement, 'X');

            this.dateFraicheur = moment(dateFraicheur, 'X');

            this.joursRestants = joursRestants;

            this.joursRestantsPourcentage = joursRestantsPourcentage;

            this.idGroupe = idGroupe;

            this.libelleGroupe = libelleGroupe;

            this.resultatCritere = resultatCritere;

            this.objectifCritere = objectifCritere;

            this.rsuroCritere = rsuroCritere;

            this.pointsCritere = pointsCritere;

            this.pointsCriterePourcent = pointsCriterePourcent;

            this.idCritere = idCritere;

            this.rang = rang;

            this.rangMoinsUn = rangMoinsUn;

            this.rangMaxClassement = rangMaxClassement;

            this.progressionRang = progressionRang;

            this.idPere = idPere;

            this.infosActeur = new Acteur(infosActeur);

            this.infosPere = new MulticritereSnapshot(infosPere);

            this.infosCritere = infosCritere.map(infoCritere => new MulticritereCritere(infoCritere));

        }
    }

    // public save : () => Promise<boolean> = () => {
    //     return this.challengeService.saveMulticritereClassement(this);
    // }

    public toDatabaseObject(): object {
        return {
            idSuiviMulticritereClassement: this.idSuiviMulticritereClassement,
            idChallenge: this.idChallenge,
            idSuivi: this.idSuivi,
            idEntite: this.idEntite,
            dateTraitement: this.dateTraitement.unix(),
            dateFraicheur: this.dateFraicheur.unix(),
            idGroupe: this.idGroupe,
            resultatCritere: this.resultatCritere,
            objectifCritere: this.objectifCritere,
            rsuroCritere: this.rsuroCritere,
            pointsCritere: this.pointsCritere,
            idCritere: this.idCritere,
            rang: this.rang,
            rangMoinsUn: this.rangMoinsUn,
            idPere: this.idPere
        }
    }
}