import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import type { RootEpic } from 'store/types';
import { isActionOf } from 'typesafe-actions';
import {
    loadGeneralStatistiquesAsync,
    loadFichesStatistiquesAsync,
} from './actions';

const loadGeneralStatistiques: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadGeneralStatistiquesAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadGeneralStatistiques(action.payload)).pipe(
                map(loadGeneralStatistiquesAsync.success),
                catchError(message => of(loadGeneralStatistiquesAsync.failure(message)))
            )
        ),
    );

const loadFichesStatistiques: RootEpic = (action$, state$, { levelUpService }) =>
    action$.pipe(
        filter(isActionOf(loadFichesStatistiquesAsync.request)),
        switchMap((action) =>
            from(levelUpService.loadFichesStatistiques(action.payload)).pipe(
                map(loadFichesStatistiquesAsync.success),
                catchError(message => of(loadFichesStatistiquesAsync.failure(message)))
            )
        ),
    );

const LevelUpStatistiquesEpics = combineEpics(
    loadGeneralStatistiques,
    loadFichesStatistiques
);

export default LevelUpStatistiquesEpics;
