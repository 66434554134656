import { combineReducers } from 'redux';
import levelUpParametresReducer from 'features/level_up/src/parametres/store/reducer'
import levelUpFichesReducer from 'features/level_up/src/fiches/store/reducer'
import levelUpQuizReducer from 'features/level_up/src/quiz/store/reducer'
import levelUpStatistiquesReducer from 'features/admin/level_up/src/statistiques/store/reducer'
import levelUpAnimationsReducers from 'features/level_up/src/animations/store/reducer'

// Pour ne pas avoir des fichiers à rallonge, les actions, reducer et epics
// de Level Up ont été séparés en plusieurs sous fichiers.

const levelUpReducer = combineReducers({
    parametres: levelUpParametresReducer,
    fiches: levelUpFichesReducer,
    quiz: levelUpQuizReducer,
    statistiques: levelUpStatistiquesReducer,
    animations: levelUpAnimationsReducers,
});

export default levelUpReducer;
